import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import classnames from "classnames";

import {
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
  Table,
  UncontrolledDropdown,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import DummyUserImg from "../../assets/images/users/user.png";
import { GetLocalStorageData } from "../../meta/GetLocalStorageData";
import {
  ConvertDateFormat,
  ConvertISODateFormat,
} from "../../common/js/ConvertDateFormat";
import { toggleRSBView } from "../../redux/reducers/ChildReducer/RightSideBarReducer";
import { Api } from "../../common/api/Api";
import { parse, format } from "date-fns";
import Column from "antd/es/table/Column";
import { ConvertSecondsToTime } from "../../common/js/ConvertSecondsToTime";
import { ParseTime } from "../../common/js/ParseTime";
import AttendanceReport from "../../pages/attendance/AttendanceReport";
import { setEmployeeLeaveType } from "../../redux/reducers/ChildReducer/EmployeeLeaveReducer";
const ProfileViewMin = () => {
  const dispatch = useDispatch();
  const { form_obj } = useSelector((state) => state.RightSideBarReducer);

  const DepartmentData = GetLocalStorageData("department") || [];
  const WorkScheduleData = GetLocalStorageData("work_schedule") || [];
  const [profileObj, setProfileObj] = useState({});
  const [empId, setEmpId] = useState(0);
  const [empuid, setEmpuid] = useState();
  const [todayDate, setTodayDate] = useState(0);
  const [individualAttendanceReportData, setIndividualAttendanceReportData] =
    useState([]);

  const [attendanceEmpReport, setAttendanceEmpReport] = useState([]);

  function getWorkScheduleName(workScheduleId, workScheduleData) {
    const workScheduleName = workScheduleData.find(
      (schedule) => schedule.id === workScheduleId
    );

    if (workScheduleName) {
      return workScheduleName.name;
    } else {
      return "Work schedule not found";
    }
  }

  const workScheduleId = form_obj.work_schedule_id;
  const workScheduleName = getWorkScheduleName(
    workScheduleId,
    WorkScheduleData
  );

  useEffect(() => {
    const currentDate = new Date();
    setTodayDate(ConvertISODateFormat(currentDate));

    if (form_obj) {
      Api({
        type: "GET",
        url: `report/get-latest-attendance-report-for-employee/?employee_id=${form_obj.id}`,
      })
        .then((res) => {
          setAttendanceEmpReport(res?.data?.data);
        })
        .catch((err) => {
          console.log("Something Went Wrong !!");
        });
      setProfileObj(form_obj);
      setEmpId(form_obj.id);
      setEmpuid(form_obj.emp_uuid);
    }
  }, [form_obj]);

  // Custom Tabs Bordered
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const navigate = useNavigate();

  const getFileIcon = (fileName) => {
    const fileType = getFileType(fileName);

    switch (fileType) {
      case "pdf":
        return (
          <div>
            <i className="ri-file-pdf-line" style={{ fontSize: "25px" }} />
          </div>
        );
      case "img":
        return (
          <div>
            <i className="ri-image-2-line" style={{ fontSize: "25px" }} />
          </div>
        );
      case "xlsx":
      case "xls":
        return (
          <div>
            <i className=" ri-file-excel-2-line" style={{ fontSize: "25px" }} />
          </div>
        );
      case "word":
        return (
          <div>
            <i className=" ri-file-word-2-line" style={{ fontSize: "25px" }} />
          </div>
        );
      default:
        return null;
    }
  };

  const getDownloadIcon = (fileName) => {
    const fileType = getFileType(fileName);

    switch (fileType) {
      case "pdf":
        return (
          <div>
            <a download target="_blank" rel="noopener noreferrer">
              <i className="ri-download-2-line" style={{ fontSize: "20px" }} />
            </a>
          </div>
        );
      case "img":
        return (
          <div>
            <a download target="_blank" rel="noopener noreferrer">
              <i className="ri-download-2-line" style={{ fontSize: "20px" }} />
            </a>
          </div>
        );
      case "xlsx":
      case "xls":
        return (
          <div>
            <a download target="_blank" rel="noopener noreferrer">
              <i className="ri-download-2-line" style={{ fontSize: "20px" }} />
            </a>
          </div>
        );
      case "word":
        return (
          <div>
            <a download target="_blank" rel="noopener noreferrer">
              <i className="ri-download-2-line" style={{ fontSize: "20px" }} />
            </a>
          </div>
        );
      default:
        return null;
    }
  };
  const navigatuser = () => {
    dispatch(toggleRSBView(false));
    navigate(`/employee/view/${empuid}`);
    Api({
      type: "GET",
      url: `leave/get-leave-type-details-for-employee/?employee_id=${empId}`,
    })
      .then((res) => {
        dispatch(setEmployeeLeaveType(res?.data?.data));
      })
      .catch((err) => {
        console.log("Somethong Went Wrong!!");
      });
  };

  const getFileType = (fileName) => {
    const extension = fileName.split(".").pop();
    // Map common file extensions to file types
    const fileTypeMap = {
      pdf: ["pdf"],
      img: ["png", "jpg", "jpeg", "gif"],
      xlsx: ["xlsx"],
      xls: ["xls"],
      word: ["doc", "docx"],
    };

    for (const [type, extensions] of Object.entries(fileTypeMap)) {
      if (extensions.includes(extension.toLowerCase())) {
        return type;
      }
    }

    // Default to 'other' if the file type is not recognized
    return "other";
  };
  return (
    <React.Fragment>
      <Row>
        <div className="p-2 text-center">
          <img
            src={profileObj.profile_url ? profileObj.profile_url : DummyUserImg}
            alt="Employee"
            className="avatar-lg img-thumbnail rounded-circle mx-auto border-success"
            style={{ objectFit: "cover" }}
          />
          <div className="mt-3">
            <h5 className="fs-15 profile-name">
              <Link to="#" className="link-primary">
                {profileObj?.fname || ""}
              </Link>
            </h5>
            <p className="text-muted profile-designation">
              {profileObj?.primary_department
                ? DepartmentData.length
                  ? DepartmentData.find(
                      (d) => d.id === profileObj?.primary_department
                    )?.name
                  : ""
                : ""}
            </p>
            <div className="  mb-3 align-center">
              <span className="text-muted">
                <i className="ri-at-line me-1 align-bottom"></i>{" "}
                <span className="view-companyname">
                  <Link
                    to={`mailto:${profileObj?.email || ""}`}
                    className="link-primary"
                  >
                    {profileObj?.email || ""}
                  </Link>
                </span>
              </span>
              <span className="text-muted">
                <i className="ri-phone-line me-1 ms-2 align-bottom"></i>{" "}
                <span className="view-location">
                  <Link
                    to={`tel:${profileObj?.contact_number || ""}`}
                    className="link-primary"
                  >
                    {profileObj?.contact_number || ""}
                  </Link>
                </span>
              </span>
            </div>
          </div>
        </div>
        {attendanceEmpReport.map((item) =>
          item.date === todayDate ? (
            <Row className="g-0 text-center">
              <>
                <Col xs={4}>
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1 profile-project">
                      {!!item?.first_check_in
                        ? format(ParseTime(item?.first_check_in), "h:mm a")
                        : "-"}
                    </h5>
                    <p className="text-muted mb-0">Check In</p>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1 profile-task">
                      {!!item?.last_check_out
                        ? format(ParseTime(item?.last_check_out), "h:mm a")
                        : "-"}
                    </h5>
                    <p className="text-muted mb-0">Check Out</p>
                  </div>
                </Col>
                <Col xs={4}>
                  <div className="p-3 border border-dashed border-end-0">
                    <h5 className="mb-1 profile-task">
                      {!!item?.total_work_duration
                        ? ConvertSecondsToTime(item?.total_work_duration)
                        : "-"}
                    </h5>
                    <p className="text-muted mb-0">Working Hrs</p>
                  </div>
                </Col>
              </>
            </Row>
          ) : null
        )}

        <div className="table-responsive table-card p-3">
          <h5 className="fs-15 mb-2 mt-3 ms-3 text-center">Basic Details</h5>
          <Table className="table table-borderless mb-0">
            <tbody>
              <tr>
                <td className="fw-medium">DOB</td>
                <td>
                  {(profileObj?.dob && ConvertDateFormat(profileObj?.dob)) ||
                    ""}
                </td>
              </tr>
              <tr>
                <td className="fw-medium">Gender</td>
                <td>
                  {profileObj?.gender === 1
                    ? "Male"
                    : profileObj?.gender === 2
                    ? "Female"
                    : ""}
                </td>
              </tr>
              <tr>
                <td className="fw-medium">Work Schedule</td>
                <td>{workScheduleName}</td>
              </tr>
              {/* <tr>
                <td className="fw-medium">Sub Departments</td>
                <td>
                  {profileObj?.sub_department
                    ? profileObj?.sub_department.map((d) => (
                      <span
                        className="badge bg-primary-subtle text-primary me-1"
                        key={1}
                      >
                        {DepartmentData.length
                          ? DepartmentData.find((e) => e.id === d).name
                          : ""}
                      </span>
                    ))
                    : null}
                </td>
              </tr> */}
              <tr>
                <td className="fw-medium">Joining Date</td>
                <td>
                  <small className="text">
                    {(profileObj?.joining_date &&
                      ConvertDateFormat(profileObj?.joining_date)) ||
                      ""}
                  </small>
                </td>
              </tr>
              {profileObj?.cf_values &&
                profileObj.cf_values.map((c) => (
                  <tr key={c.name}>
                    <td className="fw-medium">{c.name}</td>
                    <td>
                      {c.value && (
                        <>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {getFileIcon(c.value)}
                            <span
                              style={{
                                marginLeft: "5px",
                                marginRight: "5px",
                              }}
                            >
                              {c.value.substring(c.value.lastIndexOf("/") + 1)}
                            </span>
                            {getDownloadIcon(c.value)}
                          </div>

                          {/* <a
                            href={c.value}
                            download
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="ri-download-2-line" style={{ fontSize: '20px' }} />
                          </a> */}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
        <div className="p-3 border border-dashed border-end-0 border-start-0">
          <Nav
            tabs
            className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3"
          >
            <NavItem>
              <NavLink
                to="#"
                style={{ cursor: "pointer" }}
                className={customActiveTab === "1" ? "active nav-link" : "mt-2"}
                onClick={() => {
                  toggleCustom("1");
                }}
              >
                Attendance
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                style={{ cursor: "pointer" }}
                className={customActiveTab === "2" ? "active nav-link" : "mt-2"}
                onClick={() => {
                  toggleCustom("2");
                }}
              >
                Payrol
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                style={{ cursor: "pointer" }}
                className={customActiveTab === "3" ? "active nav-link" : "mt-2"}
                onClick={() => {
                  toggleCustom("3");
                }}
              >
                Activity
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={customActiveTab} className="text-muted">
            <TabPane tabId="1" id="home1">
              <SimpleBar style={{ maxHeight: "215px" }}>
                <Table className="table table-borderless mb-0">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Date</strong>
                      </td>
                      <td>
                        <strong>In</strong>
                      </td>
                      <td>
                        <strong>Out</strong>
                      </td>
                      <td>
                        <strong>Total</strong>
                      </td>
                    </tr>

                    {attendanceEmpReport.map((item) => (
                      <tr key={item.id}>
                        <td className="fw-medium">
                          {format(new Date(item.date), "dd MMM, yyyy")}
                        </td>
                        <td>
                          {!!item?.first_check_in
                            ? format(ParseTime(item?.first_check_in), "h:mm a")
                            : null}
                        </td>
                        <td>
                          {!!item?.last_check_out
                            ? format(ParseTime(item?.last_check_out), "h:mm a")
                            : item.is_week_off === true
                            ? "Week Off"
                            : item?.is_holiday === true
                            ? "Holiday"
                            : item.is_leave_approved === true
                            ? "Leave"
                            : item?.first_check_in === null &&
                              item.leave_taken_without_approval === true
                            ? "Absent"
                            : ""}
                        </td>
                        <td className={item.totalColor}>
                          {!!item?.total_work_duration
                            ? ` ${ConvertSecondsToTime(
                                item?.total_work_duration
                              )}`
                            : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </SimpleBar>
            </TabPane>
            <TabPane tabId="2">
              <p>Pending</p>
            </TabPane>
            <TabPane tabId="3">
              <SimpleBar style={{ maxHeight: "215px" }}>
                <Table className="table table-borderless mb-0">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Time</strong>
                      </td>
                      <td>
                        <strong>Event</strong>
                      </td>
                      <td>
                        <strong>Source</strong>
                      </td>
                    </tr>
                    <tr>
                      <td className="fw-medium">10 min ago</td>
                      <td>Check Out</td>
                      <td>Door Tap</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">2 hr ago</td>
                      <td>Check In</td>
                      <td>Door Tap</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">2:15 hr ago</td>
                      <td>Check Out</td>
                      <td>Door Tap</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">4:15 hr ago</td>
                      <td>Check In</td>
                      <td>Door Tap</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">4:45 hr ago</td>
                      <td>Check Out</td>
                      <td>Door Tap</td>
                    </tr>
                    <tr>
                      <td className="fw-medium">8:15 hr ago</td>
                      <td>Check In</td>
                      <td>Mobile</td>
                    </tr>
                  </tbody>
                </Table>
              </SimpleBar>
            </TabPane>
          </TabContent>
        </div>

        <div
          className="offcanvas-foorter border p-3 hstack gap-3 text-center position-sticky bottom-0 bg-white"
          style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <button className="btn btn-light w-100">
            <i className="ri-at-line align-bottom ms-1"></i> Send Email
          </button>
          <Button
            // to={`/employee/view/${empId}`}
            onClick={navigatuser}
            className="btn btn-primary w-100"
          >
            <i className="ri-user-3-line align-bottom ms-1"></i> View Profile
          </Button>
        </div>
      </Row>
    </React.Fragment>
  );
};

export default ProfileViewMin;
